/* Format Component: Without Inset Hover Button, and each row could open dropdown menu */

/* Container for the whole table */
.sortTable {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    overflow: hidden;
    background-color: #fff;
    font-family: var(--font-family-display);
    border-radius: 4px 0px 4px 4px;
}

/* Search Bar */
.sortTable-search {
    display: flex;
    padding: 8px 12px;
    background-color: var(--color-gray-50, #F9FAFB);
    border-bottom: 1px solid var(--color-gray-200);
    align-items: center;
    gap: 8px;
}

.search-input-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}

.search-icon {
    position: absolute;
    left: 10px;
    pointer-events: none;
}

.sortTable-search-input {
    flex: 1;
    height: 36px;
    padding: 8px 12px 8px 36px;
    border: 1px solid var(--color-gray-300, #D0D5DD);
    border-radius: 4px;
    font-size: var(--font-size-xs);
    color: var(--color-gray-900);
    background-color: #fff;
    outline: none;
    width: 100%;
}

.sortTable-search-input:focus {
    border-color: var(--color-primary-600, #1570EF);
    box-shadow: 0 0 0 1px var(--color-primary-600, #1570EF);
}

.sortTable-search-button, .sortTable-clear-button {
    height: 36px;
    padding: 0 16px;
    background-color: var(--color-gray-100, #F2F4F7);
    color: var(--color-gray-700, #344054);
    border: 1px solid var(--color-gray-300, #D0D5DD);
    border-radius: 4px;
    font-size: var(--font-size-xs);
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 6px;
}

.sortTable-search-button:hover, .sortTable-clear-button:hover {
    background-color: var(--color-gray-200, #EAECF0);
}

.sortTable-search-button:active, .sortTable-clear-button:active {
    background-color: var(--color-gray-300, #D0D5DD);
}

.button-icon {
    flex-shrink: 0;
}

/* Table Header */
.sortTable-header {
    display: flex;
    background-color: #F9FAFB;
    align-items: stretch;
    padding-right: 5px;
    border-bottom: 1px solid var(--color-gray-200);
}

.sortTable-header-cell {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background: #F9FAFB;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    position: relative;
    cursor: pointer;
    user-select: none;
}

.sortTable-header-cell:hover {
    background: #F0F3F9;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
}

.sort-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sort-icons-default {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -2px;
}

.sort-icons-default svg {
    margin-top: -4px;
}

.sort-icons-default svg:first-child {
    margin-bottom: -4px;
}

/* the header area right border effect */
.sortTable-header-cell::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    height: calc(100% - 24px);
    background: #D0D5DD;
    margin: auto 0;
}

.sortTable-header-cell:last-child::after {
    display: none;
}

/* Table Body */
.sortTable-body {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 137px); /* Updated to account for reduced search bar height (44px header + 52px search + 41px footer) */
    overflow-y: auto;
}

.sortTable-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.sortTable-body::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-500);
    cursor: pointer;
    border-radius: 3px;
}

.sortTable-body::-webkit-scrollbar-track {
    background-color: var(--color-gray-50);
}

.sortTable-body::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-600);
}

.rowsArea {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sortTable-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.sortTable-row.even {
    background-color: #fff;
}

.sortTable-row.odd {
    background-color: #f9f9f9;
}

/* format body UI */
.sortTable-cell {
    display: flex;
    min-width: 0;
    flex: 1;
    min-height: 50px;
    padding: 8px 24px 8px 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--color-gray-200);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
}

/* Special cell styling for model names that might expand */
.sortTable-cell.model-name-cell {
    height: auto;
    white-space: normal;
    padding: 0 24px 0 12px; /* Remove vertical padding to let badge container control it */
}

.sortTable-cell>span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    flex: 1;
}

/* Allow model names content to wrap and expand */
.sortTable-cell>span.model-names-content {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    width: 100%;
}

/* Style for model badge container */
.model-badge-container {
    width: 100%;
    transition: all 0.3s ease;
}

/*the column action takens cell */
.actionsTakens-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.actionsTakens-cell .nameText {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* the occureences button in the column action taken, used for open nested area*/
.occureencesButton {
    flex-shrink: 0;
    /* make sure the button not be hidden */
    display: flex;
    padding: 2px 4px 2px 9px;
    width: 110px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 4px;
    border: 1px solid var(--color-gray-300);
    background: var(--color-base-white);
    color: var(--color-gray-700);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-xs);
    height: 22px;
    box-sizing: border-box;
}

.Effectiveness-cell {
    display: flex;
    align-items: center;
    gap: 12px;
}

/******************************************** the dropdown area format *********************************************/
.dropdown-menu {
    display: flex;
    height: auto;
    padding: 20px 20px 20px 0px;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    background: var(--color-gray-200);
    box-sizing: border-box;
    width: 100%;
}

.nestedFormat {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    overflow: hidden;
    width: 100%;
    margin-left: 20px;
    background-color: #fff;
    font-family: var(--font-family-display);
    border-radius: 4px 0px 4px 4px;
}

/* Table Header */
.nestedFormat-header {
    display: flex;
    background-color: #F9FAFB;
    align-items: stretch;
    padding-right: 5px;
    border-bottom: 1px solid var(--color-gray-200);
}

.nestedFormatHeader-cell {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background: #F9FAFB;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    position: relative;
}

/* the header area right border effect */
.nestedFormatHeader-cell::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    height: calc(100% - 24px);
    background: #D0D5DD;
    margin: auto 0;
}

.nestedFormatHeader-cell:last-child::after {
    display: none;
}

/* Table Body */
.nestedFormat-body {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 99px);
    overflow-y: auto;
}

.nestedFormat-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.nestedFormat-row.even {
    background-color: #fff;
}

.nestedFormat-row.odd {
    background-color: #f9f9f9;
}

/* format body UI */
.nestedFormat-cell {
    display: flex;
    min-width: 0;
    flex: 1;
    height: 50px;
    padding: 8px 24px 8px 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--color-gray-200);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    white-space: nowrap;
}

.nestedFormat-cell>span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    flex: 1;
}

.issueKey-cell {
    display: flex;
    align-items: center;
    gap: 12px;
}


/******************************************** the dropdown area format *********************************************/

/* Table Footer */
.sortTable-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-top: 1px solid var(--color-gray-200);
    background: #F9FAFB;
}

.sortTable-left {
    font-size: var(--font-size-sm);
    color: var(--color-gray-600);
    font-weight: 600;
}

.search-filter-indicator {
    color: var(--color-primary-600);
    font-weight: 500;
    font-style: italic;
}

.sortTable-right {
    display: flex;
    gap: 8px;
}

/* Empty state styles */
.empty-table-placeholder {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 500px; /* Minimum height to match approximately 10 rows */
}

.empty-message {
    padding: 24px;
    text-align: center;
    color: var(--color-gray-600);
}

.empty-title {
    font-size: var(--font-size-md, 16px);
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--color-gray-700);
}

.empty-subtitle {
    font-size: var(--font-size-sm, 14px);
    color: var(--color-gray-500);
    max-width: 400px;
    margin: 0 auto;
    line-height: 1.5;
}

.empty-row {
    height: 50px; /* Match the height of a normal row */
    border-bottom: 1px solid var(--color-gray-200);
    background-color: #fff;
}

.empty-row:nth-child(odd) {
    background-color: #f9f9f9;
}