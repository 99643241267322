/* Achieve moduel: System Trends Part */
.trendsArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100vw - 52px);
    margin-top: 1px;
    margin-right: 3px;
    min-height: calc(100vh - 42px);
    max-height: calc(100vh - 42px);
    padding: 24px;
    gap: 12px;
    flex: 1 0 0;
    box-sizing: border-box;
    overflow: hidden;
    font-family: var(--font-family-display);
}

.LoadingFormatArea {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  overflow: hidden;
  background-color: #fff;
  font-family: var(--font-family-display);
  border-radius: 4px 0px 4px 4px;
  width: 100%;
  height: calc(100vh - 180px);
}

/* Achieve moduel: Systsem Level (1) and Product Level(2) */
.TrendsFilter{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.TrendsFilterLeft{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Achieve moduel: Product Level(2) */
.backButton{
  display: flex;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #D0D5DD;
  background: #fff;
  cursor: pointer;
}

.backButton:hover{
   background-color: var(--color-gray-100);
}

/* Achieve moduel: Product Level(2) */
.ShowsSystemArea {
  display: flex;
  padding: 10px 12px;
  box-sizing: border-box;
  align-items: center;
  height: 34px;
  width: 226px;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid #E4E7EC;
  background: var(--color-gray-50);

  overflow: hidden;
  color: var(--Colors-Gray-500, #667085);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px
}

/* Achieve moduel: Failure Mode Pop Window Part (3) */
.failureModeChartArea{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 12px;
}

/* Achieve moduel:Product Level(2) and Failure Mode Pop Window Part (3) */
.trendsDataFormat{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.detailWindowTitle{
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: 600;
  line-height: var(--line-height-md);
  color: var(--color-gray-800);
}

.detailWindowText{
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-sm);
  color: var(--color-gray-800);
}

/* Achieve moduel: Failure Mode Pop Window Part (3) */
.seriesMode{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Achieve moduel: Product Level(2) and Failure Mode Pop Window Part (3) */
.TrendsSingleBarArea{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 6px;
}

/* Achieve moduel: Action Taken level (4) occureences  */
.occureencesButton-icon {
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease-in-out;
}

.occureencesButton-icon.open {
  transform: rotate(180deg);
}