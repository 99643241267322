.addChartArea{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: var(--font-family-display);
    gap: 10px;
}

.titleArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
}

.addChartTitle{
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 600;
  line-height: var(--line-height-md);
  color: var(--color-gray-800);
}

.addChartText{
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-sm);
  color: var(--color-gray-600);
}

.searchArea {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  align-self: stretch;
  margin-bottom: 14px;
  gap: 12px;
}


.changeTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-top: 14px;
  width: 100%;
}

.inputValueArea{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 12px;
  flex: 1;
}

.changeTitle-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex: 1 0 0;
  height: 34px;
  box-sizing: border-box;
  padding: 10px 12px;
  align-self: stretch;
  border: 1px solid var(--color-gray-200);
  background: var(--color-gray-50);
  font-family: var(--font-family-display);
}

.changeTitle-box:hover{
  border-color: var(--brand-secondary-400);
}

.changeTitle-box.isSearched{
  border-color: var(--brand-secondary-400);
  background: var(--brand-secondary-25);
}

.changeTitle-box:focus-within {
  border-color: var(--brand-secondary-400);
  background: var(--brand-secondary-25);
}

.changeTitle input {
  border: none;
  outline: none;
  background: transparent;
  font-size: 12px;
  padding: 5px;
  width: auto;
  flex-grow: 0;
  width: 100%;
}


