/* the filter menu used of Achieve module Trends Page*/
.SearchArea{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family-display);
}

.viewByArea {
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 34px;
    min-width: 60px;
    max-width: 60px;
    flex: 1 0 0;
    align-self: stretch;
    border-top: 1px solid var(--color-gray-200);
    border-bottom: 1px solid var(--color-gray-200);
    background: var(--color-gray-200);
    color: var(--color-gray-500);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-xxs);
}

.achievDropDownArea::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, var(--color-base-white), transparent);
  pointer-events: none;
}

.achievDropDownArea {
  flex: 1;
  overflow-y: auto;
  max-height: 250px;
}

.achievDropDownArea::-webkit-scrollbar {
  width: 5px;
  padding: 4px;
}

.achievDropDownArea::-webkit-scrollbar-thumb {
  background: var(--color-gray-500);
}

.achievDropDownArea::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-600);
}

.achievDropDownArea::after {
  display: none;
}