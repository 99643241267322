.milestonesPage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100vw - 52px);
    margin-top: 1px;
    margin-right: 3px;
    min-height: calc(100vh - 42px);
    max-height: calc(100vh - 42px);
    padding: 24px;
    gap: 20px;
    flex: 1 0 0;
    box-sizing: border-box;
    overflow: hidden;
    font-family: var(--font-family-display);
    overflow-y: auto;
}

.milestonesPage::-webkit-scrollbar {
    width: 7px;
}

.milestonesPage::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-300);
    cursor: pointer;
    border-radius: 3px;
}

.milestonesPage::-webkit-scrollbar-track {
    background-color: var(--color-gray-50);
}

.milestonesPage::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-400);
}

.PageText {
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-md);
    color: var(--color-gray-950);
}