/* Milestones Format Component: Without Inset Hover Button, and nested area */

/* Container for the whole table */
.MilestonesFormat {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    overflow: hidden;
    background-color: #fff;
    font-family: var(--font-family-display);
    border-radius: 4px 0px 4px 4px;
}

/* Table Header */
.MilestonesFormat-header {
    display: flex;
    background-color: #F9FAFB;
    align-items: stretch;
    padding-right: 5px;
    border-bottom: 1px solid var(--color-gray-200);
}

.MilestonesFormatHeader-cell {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background: #F9FAFB;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    position: relative;
}

/* the header area right border effect */
.MilestonesFormatHeader-cell::after {
    content: "";
    position: absolute;
    top: 0;  
    bottom: 0; 
    right: 0;
    width: 1px;
    height: calc(100% - 24px);  
    background: #D0D5DD;
    margin: auto 0; 
}

.MilestonesFormatHeader-cell:last-child::after {
    display: none;
}

/* Table Body */
.MilestonesFormat-body {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 99px);
    overflow-y: auto;
}

.MilestonesFormat-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.MilestonesFormat-body::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-500);
    cursor: pointer;
    border-radius: 3px;
}

.MilestonesFormat-body::-webkit-scrollbar-track {
    background-color: var(--color-gray-50);
}

.MilestonesFormat-body::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-600);
}

.MilestonesFormatRowsArea{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.MilestonesFormat-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.MilestonesFormat-row.even {
    background-color: #fff;
}

.MilestonesFormat-row.odd {
    background-color: #f9f9f9;
}

/* format body UI */
.MilestonesFormat-cell {
    display: flex;
    min-width: 0;
    flex: 1;
    height: 50px;
    padding: 8px 24px 8px 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--color-gray-200);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    white-space: nowrap;
}

.MilestonesFormat-cell > span {   
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    flex: 1;
}

/*the column action takens cell */
/* .actionsTakens-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;  
}

.actionsTakens-cell .nameText {
    min-width: 0;  
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Effectiveness-cell {
    display: flex;
    align-items: center;
    gap: 12px;
} */


/* Table Footer */
.MilestonesFormat-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    max-height: 54px;
    min-height: 54px;
    box-sizing: border-box;
    border-top: 1px solid #E4E7EC;
    background: var(--color-base-white);
}

.MilestonesFormat-left {
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-md);
    /* 150% */
    color: var(--color-gray-900);
}

.MilestonesFormat-right {
    display: flex;
    gap: 10px;
}