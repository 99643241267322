.datacard{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    padding: 20px 16px;
    border-radius: var(--radius-xs);
    background: var(--colors-gray-light-mode-50, #F9FAFB);
    font-family: var(--font-family-display);
    flex-shrink: 0;
}

.datacard .titleText{
    color: var(--color-gray-900);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-sm); 
}

.datacard .context {
  color: var(--color-gray-800);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-sm);
  margin-bottom: 8px;
  cursor: pointer;
  overflow: hidden; /* Required for line-clamp to work */
  display: -webkit-box; /* WebKit-based browsers */
  -webkit-box-orient: vertical; /* WebKit-specific */
  box-orient: vertical; /* Older spec fallback */
  transition: max-height 0.3s ease;
  white-space: pre-line;
}

.datacard .context.collapsed {
  /* Standard line-clamp (fallback for -webkit-line-clamp) */
  line-clamp: 4;
  -webkit-line-clamp: 4; /* WebKit-specific */
}

.datacard .context.expanded {
  /* Remove line clamp for expanded state */
  line-clamp: none;
  -webkit-line-clamp: unset;
}

/* the source link text and display layout */
.sourceText {
    color: var(--blue-700) !important; /* the green color */
    font-size: var(--font-size-xs);
    text-decoration: none !important; /* no text decoration */
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-sm); 
    user-select: pointer !important;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
}

/* the image area should display as center */
.imageDataArea{
  display: flex;
  justify-content: center;
  align-self: stretch;
}

/*the button area of share, save, thumbDown, AI button*/
.buttonArea{
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}


/*the share button open dropmenu: display all team meber could share*/
.shareMenu{
  display: flex;
  width: 188px; 
  padding: 8px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  background-color: white;
  border-radius: 4px;
  z-index: 1000;
  border: 1px solid var(--Gray-200, #E4E7EC);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.shareMenu .dropDownHeader{
  display: flex;
  height: 15px;
  padding: 1px 8px;
  padding-bottom: 6px;
  align-items: center;
  align-self: stretch;
  gap: 10px;
  border-bottom: 1px solid #D0D5DD;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-sm);
}

/*the share menu select item*/
.shareSelectArea{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
  max-height: 80px !important;
}

.shareSelectArea::-webkit-scrollbar {
  width: 3px;
  padding: 4px;
}

.shareSelectArea::-webkit-scrollbar-thumb {
  background: var(--color-gray-500);
  border-radius: 1000px;
}

.shareSelectArea::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-600);
}

/* the team member name list */
.shareSelectArea .dropDownItem{
  display: flex; 
  height: 10px;
  padding: 6px 8px;
  align-items: center;
  align-self: stretch;
}

/*the loading animation of share menu load team member data*/
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  color: var(--color-gray-500);
  font-size: var(--font-size-sm);
}