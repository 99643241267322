/* this search filed using for Ahcieve search */
.achieve-search {
  display: inline-block;
}

/*the search box area UI */
.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex: 1 0 0;
  height: 34px;
  box-sizing: border-box;
  padding: 10px 12px;
  align-self: stretch;
  border: 1px solid var(--color-gray-200);
  background: var(--color-gray-50);
  font-family: var(--font-family-display);
}

.search-box:hover{
  border-color: var(--brand-secondary-400);
}

.search-box.isSearched{
  border-color: var(--brand-secondary-400);
  background: var(--brand-secondary-25);
}

.search-box:focus-within {
  border-color: var(--brand-secondary-400);
  background: var(--brand-secondary-25);
}

.search-box svg{
  width: 16px;
  height: 16px;
  z-index: 10;
  min-width: 16px;
  margin-right: 8px;
}

.hidden-text {
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  font-size: 12px;
  padding: 5px;
}

input {
  border: none;
  outline: none;
  background: transparent;
  font-size: 12px;
  padding: 5px;
  width: auto;
  flex-grow: 0;
}

.achieveSearchDropdown {
  position: absolute;
  top: 33px;
  left: -1px;
  max-width: 100%;
  min-width: 100%;
  background: white;
  border: 1px solid var(--brand-secondary-400);
  border-top: 1px solid transparent;
  max-height: 200px;
  overflow-y: auto;
  padding-top: 1px;
  z-index: 10;
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-sm);
}

/* the scorll bar of dashboard area*/
.achieveSearchDropdown::-webkit-scrollbar {
  width: 4px !important;
}

.achieveSearchDropdown::-webkit-scrollbar-track {
  background: white !important;
}

.achieveSearchDropdown::-webkit-scrollbar-thumb {
  background: var(--color-gray-400);
  border-radius: 4px;
}

.achieveSearchDropdown-item {
  padding: 4px 16px;
  margin: 2px 4px;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
}

.achieveSearchDropdown-item:hover {
  border-color: var(--brand-secondary-400);
  background: var(--brand-secondary-25);
}

