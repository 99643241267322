.designChartArea {
    display: flex;
    flex-direction: column;
    padding: 18px 0px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;
    border: 1px solid #F2F4F7;
    box-sizing: border-box;
}

.designChartAreaTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 54px;
    max-height: 54px;
    box-sizing: border-box;
    padding: 18px 23px;
    padding-top: 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #F2F4F7;
    font-family: var(--font-family-display);    
    color: #101828;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height:  18px; /* 150% */
}

.designChartArea .chartBackground {
    width: 100%;
    min-height: calc(100% - 54px);;
    max-height: calc(100% - 54px);
    padding: 0px 23px;
    padding-top: 6px;
    box-sizing: border-box;
}

/*the Error Message UI */
.ErrorMessageUI{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/*the Error Message Title */
.errorTitle{
    color: #D92D20;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.errorText {
    color: #101828;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    max-width: 400px;
}