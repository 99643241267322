/* Date picker area style */
.datePicker{
  display: flex;
  position: relative;
  min-width: 245px;
  max-width: 245px;
}

.selectDateArea {
  display: flex;
  padding: 5px 7px;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #E4E7EC;
  min-width: 245px;
  max-width: 245px;
  height: 34px;
  background: #FFF;
  box-sizing: border-box;
}

.date-text {
  text-align: center; 
  white-space: nowrap;  
  color: var(--color-gray-500);
  font-family: var(--font-family-display);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
}

.arrowDown-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease-in-out;
  color: #667085;
}

.arrowDown-icon.open{
  transform: rotate(180deg);
}

.datePickerArea {
  position: absolute;
  top: 33px;
  right: 0; 
  border: 1px solid #E5E7EB;
  border-radius: 2px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: var(--font-family-display);
  z-index: 1000;
}

.date-picker {
  display: flex;
  height: calc(100% - 60px); /* Subtract the footer area height */
}

/* Sidebar style */
.date-picker-sidebar {
  display: flex;
  width: 128px;
  box-sizing: border-box;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-right: 1px solid var(--color-gray-200);
  background: #fff;
  font-family: var(--font-family-display);
}

.date-picker-sidebar button {
  border: none;
  background: transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  font-family: var(--font-family-display);
}

.date-picker-sidebar button.active {
  background: var(--color-gray-50);
}

/* Date picker content style */
.date-picker-content {
  flex: 1;
  padding: 20px 24px;
  color: #344054;
}

/* Calendar header style */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.month-selector {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.month-selector .monthText {
  color: var(--color-gray-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.year-nav {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100px;
}

/*the selection menu used for the card drop down menu */
.yearSelectionMenuArea::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, var(--color-base-white), transparent);
  pointer-events: none;
}

.yearSelectionMenuArea {
  flex: 1;
  overflow-y: auto;
  max-height: 220px;
}

.yearSelectionMenuArea::-webkit-scrollbar {
  width: 5px;
  padding: 4px;
}

.yearSelectionMenuArea::-webkit-scrollbar-thumb {
  background: var(--color-gray-500);
}

.yearSelectionMenuArea::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-600);
}

.yearSelectionMenuArea::after {
  display: none;
}

.nav-button {
  border: 1px solid #D1D5DB;
  background: #FFFFFF;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #4B5563;
  font-size: 14px;
  padding: 0;
}

.nav-button:hover {
  background-color: #F9FAFB;
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.month-select:focus {
  outline: #E4E7EC;
  border-color: #E4E7EC;
  box-shadow: none;
}

/* Calendar body style */
.calendar-body {
  padding-top: 12px;
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 8px;
}

.weekday {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #344054;
  padding: 10px 8px;
  font-weight: 500;
}

.calendar-days {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.calendar-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
}

.calendar-day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  padding: 10px 8px;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
}

.calendar-day:hover:not(.disabled):not(.selected) {
  background-color: #F9FAFB;
}

.outside-month {
  color: #D1D5DB;
}

.today {
  font-weight: bold;
}

.disabled {
  color: #D1D5DB;
  cursor: not-allowed;
}

.selected {
  background-color: #CCFBEF;
  color: var(--color-gray-900);
  font-weight: normal;
}

.start-date, .end-date {
  z-index: 10;
}

.in-range {
  background-color: #F9FAFB;
  border-radius: 0;
  z-index: 5;
}

/* footer area UI style */
.date-picker-footer {
  height: 60px;
  border-top: 1px solid #E5E7EB;
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 10px;
}

.date-input {
  box-sizing: border-box;
  height: 32px;
  width: 120px;
  color: var(--color-gray-500);
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 14px;
  color: var(--color-gray-900);
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.highlight-border {
  border: 2px solid var(--brand-secondary-400);
}

.date-picker-actions {
  margin-left: auto;
  display: flex;
  gap: 8px;
}