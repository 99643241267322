.statCardArea {
  display: flex;
  gap: 16px;
  width: 100%;
}

.statCard {
  height: 66px;
  width: 228.8px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  gap: 6px;
  flex: 1;
  box-shadow: 0px 1px 2px 0px #0A0D120D;
}

.label {
  font-weight: 500;
  font-size: var(--font-size-xs);
  line-height: var(--font-height-xs);
  color: var(--color-gray-500);
}

.valueRow {
  display: flex;
  align-items: baseline;
  gap: 16px;
}

.number {
  font-size: var(--font-size-lg);
  font-weight: 600;
  line-height: var(--font-height-lg);
  color: #333;
}

.percentage {
  font-size: var(--font-size-md);
  color: #666;
} 