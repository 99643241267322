/* Achieve moduel: Issue Overview Part */
.IssueOverviewArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100vw - 52px);
    margin-top: 1px;
    margin-right: 3px;
    min-height: calc(100vh - 42px);
    max-height: calc(100vh - 42px);
    padding: 24px;
    gap: 12px;
    flex: 1 0 0;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
}

/* the scorll bar of dashboard area*/
.IssueOverviewArea::-webkit-scrollbar {
  width: 7px !important;
}

.IssueOverviewArea::-webkit-scrollbar-track {
  background: white !important;
}

.IssueOverviewArea::-webkit-scrollbar-thumb {
  background: var(--color-gray-300);
  border-radius: 4px;
}

/* the issue overview page tittle  */
.issueDetailsTitleArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 8px 0px;
    gap: 12px;
    box-sizing: border-box;
    width: 100%;
}

/* the issue overview page title (left Area) */
.issueDetailsTitleAreaLeft{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    white-space: nowrap; /* not change text line */
    overflow: hidden; /* hideen the over size part */
}

/* the issue overview page title (left) */
.issueDetailsTitleAreaLeft .pageTitle{
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-md); /* 150% */
    color: var(--color-gray-950);
}

/* the issue overview page title (left) */
.issueDetailsTitleAreaLeft .pageSubTitle{
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-sm); /* 150% */
    color: var(--color-gray-600);
}

/* the issue overview page tittle (right dropdown menu) */
.issueDetailsTitleAreaRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    flex: 1 0 0;
}

/* the dynamic time line Tab button area */
.issueDetailsTitleAreaRight .timeLineArea {
    display: flex;
    padding: 4px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--color-dark-gray-50);
}

/*the bar chart, pie chart area*/
.chartOverview{
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
}

.locationChart{
  display: flex;
  margin-top: 12px;
  width: 100%;
}

/* the issue overview chart area */
.issueOverviewChart{
  display: flex;
  margin-top: 12px;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
}

/* Search bar and filters container */
.filters-container {
    width: 100%;
    position: relative;
}

/* Achievement Search styling */
.achieve-search-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
    width: 100%;
}

.search-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    flex: 1;
    height: 40px;
    box-sizing: border-box;
    padding: 10px 12px;
    border: 1px solid var(--color-gray-200);
    background: var(--color-gray-50);
    border-radius: 4px;
    font-family: var(--font-family-display);
}

.search-box:hover {
    border-color: var(--brand-secondary-400);
}

.search-box.isSearched {
    border-color: var(--brand-secondary-400);
    background: var(--brand-secondary-25);
}

.search-box:focus-within {
    border-color: var(--brand-secondary-400);
    background: var(--brand-secondary-25);
}

.search-box svg {
    width: 16px;
    height: 16px;
    z-index: 10;
    min-width: 16px;
    margin-right: 8px;
}

.search-input {
    border: none;
    outline: none;
    background: transparent;
    font-size: var(--font-size-xs);
    padding: 5px;
    width: 100%;
    color: var(--color-gray-900);
}

.search-clear-icon {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: background-color 0.2s;
}

.search-clear-icon:hover {
    background-color: var(--color-gray-200);
}

.search-clear-icon svg {
    margin-right: 0;
}

/* Model filter button specific styles */
.model-filter-button {
    height: 40px;
    padding: 0 16px;
    background-color: var(--color-gray-100, #F2F4F7);
    color: var(--color-gray-700, #344054);
    border: 1px solid var(--color-gray-300, #D0D5DD);
    border-radius: 4px;
    font-size: var(--font-size-xs);
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 6px;
}

.model-filter-button:hover {
    background-color: var(--color-gray-200, #EAECF0);
}

.model-filter-button:active {
    background-color: var(--color-gray-300, #D0D5DD);
}

.model-filter-button.active {
    border-color: var(--color-primary-600, #1570EF);
    color: var(--color-primary-700, #175CD3);
    background-color: var(--color-primary-50, #EFF8FF);
}

.filter-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 18px;
    height: 18px;
    padding: 0 6px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    background-color: var(--color-primary-600, #1570EF);
    border-radius: 10px;
}

.button-icon {
    flex-shrink: 0;
}

/* Model filter dropdown */
.model-filter-dropdown {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    width: 300px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    z-index: 100;
    border: 1px solid var(--color-gray-200);
    display: flex;
    flex-direction: column;
}

.model-filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid var(--color-gray-200);
}

.model-filter-header h4 {
    margin: 0;
    font-size: var(--font-size-sm);
    font-weight: 600;
    color: var(--color-gray-900);
}

.clear-model-filters {
    background: none;
    border: none;
    padding: 0;
    color: var(--color-primary-600, #1570EF);
    font-size: var(--font-size-xs);
    font-weight: 500;
    cursor: pointer;
}

.model-checkbox-list {
    padding: 12px;
    max-height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.model-checkbox-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.model-checkbox-item input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.model-checkbox-item label {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.no-models-message {
    padding: 16px;
    color: var(--color-gray-500);
    text-align: center;
    font-size: var(--font-size-xs);
}

.model-filter-footer {
    padding: 12px 16px;
    border-top: 1px solid var(--color-gray-200);
    display: flex;
    justify-content: flex-end;
}

.apply-model-filters {
    padding: 8px 16px;
    background-color: var(--color-primary-600, #1570EF);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: var(--font-size-xs);
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.apply-model-filters:hover {
    background-color: var(--color-primary-700, #175CD3);
}

/* Active filters display */
.active-filters {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background-color: var(--color-primary-50, #EFF8FF);
    border-radius: 4px;
    margin-bottom: 12px;
}

.active-filters-label {
    font-size: var(--font-size-xs);
    font-weight: 600;
    color: var(--color-primary-700, #175CD3);
    margin-right: 8px;
}

.active-filters-text {
    font-size: var(--font-size-xs);
    color: var(--color-gray-700);
}

/* No data state */
.no-data-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
}

.no-data-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px;
    background: var(--color-gray-50);
    border: 1px dashed var(--color-gray-300);
    border-radius: 8px;
    text-align: center;
    min-width: 320px;
    max-width: 500px;
}

.no-data-message h3 {
    margin: 16px 0 8px;
    color: var(--color-gray-900);
    font-size: var(--font-size-md);
    font-weight: 600;
}

.no-data-message p {
    margin: 0 0 24px;
    color: var(--color-gray-600);
    font-size: var(--font-size-sm);
    line-height: 1.5;
}

.clear-all-filters-button {
    padding: 8px 16px;
    background-color: var(--color-primary-600, #1570EF);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: var(--font-size-sm);
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.clear-all-filters-button:hover {
    background-color: var(--color-primary-700, #175CD3);
}

.failure-mode-text.collapsed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.failure-mode-text.expanded {
    white-space: normal;
    overflow: visible;
    word-break: break-word;
}