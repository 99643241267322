.form-input {
    padding: 12px;
    font-size: 14px;
    width: 100%;
    border: 1px solid var(--color-gray-300);
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
.form-input:focus {
border-color: var(--blue-600);
}

.register-button {
padding: 12px;
width: 100%;
background-color: var(--blue-600);
color: white;
border: none;
border-radius: 8px;
font-weight: bold;
cursor: pointer;
transition: background-color 0.3s ease;
}

.register-button:hover {
background-color: var(--blue-700);
}

