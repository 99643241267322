/*this is used for the navigation bar and left sild menu part*/
@import "../../../App.css";

.navigation {
  display: flex;
  width: calc(100% - 32px);
  margin: 0 auto;
  font-family: var(--font-family-body);
  position: relative;
  z-index: 999;
}

/*the navigation bar before user login*/
.navigation-pre-login {
  display: flex;
  height: 80px;
  width: calc(100% - 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 1024px;
  max-height: 80px;
}

/*the navigation bar after user login*/
.navigation-post-login {
  display: flex;
  min-height: 48px;
  max-height: 48px;
  box-sizing: border-box;
  min-width: 1024px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(50px);
}

/*the navigation context including menu item, account button*/
.navigation-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

/*the navigation bar left area: including company logo and menu item*/
.navigation-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navigation-left img {
  width: 104px;
  height: 20px;
  cursor: pointer;
  user-select: none; /* not allowed could click to dragging the image */
  -webkit-user-drag: none;  /* not allowed could click to dragging the image */
}


.navigation-logo {
  width: 156px;
  height: 62px;
}

/*the company logo after user login*/
.navigation-logo-post-login {
  width: 97px;
  height: 40px;
}

/*the company logo before user login*/
.navigation-logo-pre-login {
  width: 156px;
  height: 62px;
}

/*the menu items array part*/
.menu-items {
  display: flex;
  margin-left: var(--space-4xl);
  gap: var(--space-3xl);
}

/*the signle menu item*/
.menu-item {
  display: flex;
  align-items: center;
  padding: var(--space-sm) var(--space-md);
  gap: var(--font-size-xs);
  border: none;
  color: var(--color-gray-500);
  background: var(--color-white);
  cursor: pointer;
  border-radius: var(--radius-xs);
  font-family: var(--font-family-body);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-sm); /* 142.857% */
}
 

/*The default status button*/
.menu-item:hover {
  background-color: var(--color-gray-100);
}

/*The default status with Pressed*/
.menu-item:active {
  background-color: var(--color-gray-200);
}

/*The selected status button with hover*/
.menu-item.selected {
  background-color: var(--color-gray-100);
  font-weight: var(--font-weight-semibold); 
  color: var(--color-gray-500);
}

/*The selected status button with hover*/
.menu-item.selected:hover {
  background-color: var(--color-gray-200);
  color: var(--color-gray-500);
}

/*The selected status button with Pressed*/
.menu-item.selected:active {
  background-color: var(--color-gray-300);
  color: var(--color-gray-500);
}

/*The button content including icon and label text*/
.menu-item-content {
  display: flex;
  align-items: center;
  height: 24px;
  gap: 8px;
}

.auth-buttons {
  display: flex;
  gap: 8px;
}

.button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button-login {
  background-color: var(--color-white);
  color: var(--color-gray-900);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-md); /* 150% */
}

.button-signup {
  background: var(--color-gray-950);
  border-radius: var(--radius-xs);
  border: var(--radius-xxs) solid
    var(--Gradient-skeuemorphic-gradient-border, rgba(255, 255, 255, 0.12));
  box-shadow:
    0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset,
    0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05) instead;
  color: var(--color-white);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-md); /* 150% */
}

/*the account button layout */
.button-account {
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 2px 5px 2px 3px;
  border-radius: var(--radius-full);
  background: var(--color-dark-gray-50);
}

/*the account button: with hover status */
.button-account:hover {
  background: var(--color-gray-100);
}

/*the account button: Pressed*/
.button-account:active {
  background: var(--color-gray-300);
  border: none;
}

/*the account button: selected status*/
.button-account.selected {
  background: var(--color-gray-200);
  border: none;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease-in-out;
}

.arrow-icon.open{
  transform: rotate(180deg);
}


.naviDropdown-menu {
  position: absolute;
  right: 16px;
  top: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 150px;
  margin-top: 2px;
  padding: 4px;
  opacity: 0;
  z-index: 0;
  transform: scale(0.95);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.naviDropdown-menu.show {
  transform: scale(1);
  opacity: 1;
  z-index: 999;
}

.naviDropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 40px;
  width: 100%;
  text-align: left;
  background-color: white;
  border: none;
  cursor: pointer;
  color: var(--color-gray-500);
  /* Text sm/Semibold */
  font-family: var(--font-family-display);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-sm); /* 142.857% */
  gap: 8px;
  border-radius: var(--radius-xs);
  margin-bottom: 4px;
}

.naviDropdown-item:hover {
  background-color: var(--color-gray-100);
}

.naviDropdown-Logout{
  display: flex;
  align-items: center;
  padding: 10px;
  height: 40px;
  width: 100%;
  text-align: left;
  background-color: var(--danger-500);;
  border: none;
  cursor: pointer;
  color: var(--color-base-white);
  /* Text sm/Semibold */
  font-family: var(--font-family-display);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-sm); /* 142.857% */
  gap: 8px;
  border-radius: var(--radius-xs);
}

.naviDropdown-Logout:hover {
  background-color: var(--danger-600);
}

.naviDropdown-Logout:hover svg {
  color: var(--color-base-white);
}

.userLogo{
  height: 29px;
  width: 29px;
  border-radius: 50%;
  margin-right: 5px;
}