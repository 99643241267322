.optimizationsPage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100vw - 52px);
    margin-top: 1px;
    margin-right: 3px;
    min-height: calc(100vh - 42px);
    max-height: calc(100vh - 42px);
    padding: 24px;
    gap: 24px;
    flex: 1 0 0;
    box-sizing: border-box;
    font-family: var(--font-family-display);
    overflow-y: auto;
}

.optimizationsPage::-webkit-scrollbar {
    width: 7px;
}

.optimizationsPage::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-300);
    cursor: pointer;
    border-radius: 3px;
}

.optimizationsPage::-webkit-scrollbar-track {
    background-color: var(--color-gray-50);
}

.optimizationsPage::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-600);
}


/*the optimization format area (Breakdown by issues)*/
.optimizationsFormat{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
}

.optimizationsFormatTitle{
    display: flex;
    padding: 0px 12px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 44px;
    width: calc(100% + 2px);
    align-self: stretch;
    border-radius: 4px 0px 0px 0px;
    border: 1px solid var(--color-gray-200);
    border-bottom: none;
    color: var(--color-gray-900);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    box-sizing: border-box;
}

.locationColumnUI{
    color: #1570EF;
    font-weight: 600;
}

.supplierColumn {
    font-weight: 500;
}