.PendingPage{
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    padding-top: 64px;
    background:#FFF;
    font-family: var(--font-family-display);
}

.detailsArea {
    display: flex;
    width: 560px;
    max-width: 560px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.CompanyLogo {
    display: flex;
    width: 156px;
    height: 38px;
    justify-content: center;
    align-items: center;
}

.pendingTitle{
    text-align: start;
    font-weight: 600;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 150% */
    color: var(--color-gray-900);
    letter-spacing: -1.8px;
}

.pendingText{
    text-align: start;
    font-weight: 400;
    font-size: 20px;
    font-style: normal;
    line-height: 30px; /* 150% */
    color: var(--color-gray-500);
}


.contactPending{
    display: flex;
    flex-direction: column;
    align-items: f;
    justify-content: flex-start;
}

.contactPending .title {
    text-align: start;
    font-weight: 600;
    font-size: var(--font-size-lg);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-xl); /* 150% */
    color: var(--color-gray-900);
}

.contactPending .subTitle {
    text-align: start;
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-md);  
    margin-bottom: 12px;
    color: var(--color-gray-600);
}

.contactPending .emailText {
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-sm);  
    text-decoration: none;
    color: #1570EF;
}