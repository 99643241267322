/* Format Table: used for our Achieve mdoule Trends page shows data */

/* Container for the whole table */
.achieveFormat-table {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    overflow: hidden;
    background-color: #fff;
    font-family: var(--font-family-display);
    border-radius: 4px 0px 4px 4px;
}

/* Table Header */
.achieveTable-header {
    display: flex;
    background-color: #F9FAFB;
    align-items: stretch;
    padding-right: 5px;
    border-bottom: 1px solid var(--color-gray-200);
}

.formatHeader-cell {
    display: flex;
    white-space: nowrap;    
    overflow: hidden;       
    text-overflow: ellipsis;   
    height: 44px;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background: #F9FAFB;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs); /* 150% */
    color: var(--color-gray-900);
    position: relative;
}

/* the header area right border effect */
.formatHeader-cell::after {
    content: "";
    position: absolute;
    top: 0;  
    bottom: 0; 
    right: 0;
    width: 1px;
    height: calc(100% - 24px);  
    background: #D0D5DD;
    margin: auto 0; 
}

.formatHeader-cell:last-child::after {
    display: none;
}


/* Table Body */
.achieveTable-body {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 99px);
    overflow-y: auto;
}

.achieveTable-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.achieveTable-body::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-500);
  cursor: pointer;
  border-radius: 3px;
}

.achieveTable-body::-webkit-scrollbar-track {
  background-color: var(--color-gray-50);
}

.achieveTable-body::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-600);
}

.achieveTable-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.achieveTable-row.even {
    background-color: #fff;
}

.achieveTable-row.odd {
    background-color: #f9f9f9;
}

 /* the column product name UI */
.product-cell {
    display: flex;
    align-items: center;
    gap: 12px;
}

 /* format body UI */
 .formatTable-cell {
    display: flex;
    min-width: 0; 
    flex: 1;
    height: 50px;
    padding: 8px 24px 8px 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--color-gray-200);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    
    white-space: nowrap;    
    overflow: hidden;       
    text-overflow: ellipsis;
}

/*add the span attribute*/
.formatTable-cell > span {   
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    flex: 1;
}

/* Table Footer */
.achieveTable-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    min-height: 54px;
    max-height: 54px;
    box-sizing: border-box;
    border-top: 1px solid #E4E7EC;
    background: var(--color-base-white);
}

.achieveFooter-left {
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-md); /* 150% */
    color: var(--color-gray-900);
}

.achievFooter-right {
    display: flex;
    gap: 10px;
}
