/*the key specification demo css (new) */
.loadingArea {
  height: 100%;
  min-height: calc(100vh - 88px);
  max-height: calc(100vh - 88px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.demoAreaBox {
  height: calc(100vh - 88px);
  display: flex;
  flex-direction: column;
  flex: 1;
}

/*the whole area of right data*/
.demoSpecArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  user-select: text;
  min-height: calc(100vh - 88px);
  flex: 1;
}

/*the key specification area and standard area*/
.keySpecArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  user-select: text;
  min-height: 0;
  /* Needed for flex containers with overflow */
  transition: flex 0.3s ease;
}

.keySpecArea.collapsed {
  flex: 0 0 auto;
  height: 48px;
  /* Same height as collapsed standArea */
}

/* When standArea is expanded - apply 50/50 split */
.standArea.expanded {
  flex: 1;
  height: auto;
  min-height: 48px;
}

.standArea.expanded~.keySpecArea.expanded {
  max-height: 50%;
  /* Only constrain height when in 50/50 mode */
}

/* When standArea is not expanded, keySpecArea takes all available space */
.standArea:not(.expanded) {
  flex: 0 0 auto;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}

.standArea:not(.expanded)~.keySpecArea.expanded {
  max-height: none;
  /* No max height constraint when standArea is collapsed */
}

.standArea {
  display: flex;
  flex-direction: column;
  user-select: text;
  transition: all 0.3s ease;
  overflow: hidden;
}

.standArea .content-container {
  display: none;
  /* Hide completely when not expanded */
  height: 0;
  overflow: hidden;
}

.standArea.expanded .content-container {
  display: flex;
  flex-direction: column;
  height: auto;
  flex: 1;
  overflow: auto;
}

/* Common title styles */
.keySpecTitle,
.standTitle {
  display: flex;
  height: 32px;
  padding: 8px 12px 8px 12px;
  align-items: center;
  white-space: nowrap;
  gap: 6px;
  border-bottom: 2px solid var(--Colors-Border-border-secondary);
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  justify-content: space-between;
}

.IssueIDTitle {
  display: flex;
  height: 28px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  background-color: var(--color-base-white);
  border: 1px solid var(--color-gray-300);
  border-radius: var(--radius-xs);
  font-family: var(--font-family-body);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-md);
}

.keySpecMenu,
.standMenuArea {
  display: flex;
  height: 32px;
  padding-top: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 8px 12px;
  border-bottom: 2px solid #E4E7EC;
}

.keySpecMenuList,
.standMenuList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 50px);
  gap: 4px;
  white-space: nowrap;
  cursor: grab;
  overflow: hidden;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  -webkit-overflow-scrolling: touch;
  user-select: none;
}

.pageNavigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  padding-top: 4px;
}

/*the left/right move button layout*/
.scrollSpeciButton {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
  background: var(--color-base-white);
  cursor: pointer;
  z-index: 10;
}

.scrollSpeciButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* the border line to split the specification area with standard area*/
.borderLine {
  border-top: 2px solid var(--Colors-Border-border-secondary);
  margin-bottom: 4px;
}

/*the specification format used to display the data card and accordtion item*/
.demoKeySpecFormat {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: default;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 12px;
  padding-top: 6px;
  user-select: text;
}

.demoKeySpecFormat::-webkit-scrollbar {
  width: 3px !important;
}

.demoKeySpecFormat::-webkit-scrollbar-track {
  background: white !important;
}

.demoKeySpecFormat::-webkit-scrollbar-thumb {
  background: var(--color-gray-500);
  border-radius: 4px;
}

/* Toggle icon for collapse/expand */
.toggle-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

/* Make sure content is properly scrollable in each section */
.standArea.expanded .demoKeySpecFormat,
.keySpecArea.expanded .demoKeySpecFormat {
  overflow-y: auto;
  flex: 1;
}