/* 1. the login component css */
.container{
  display: flex;
  width: 100vw;
  height: calc(100vh - 24px);
  padding: 24px 0px;
}

.loginComp {
  height: 100%;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 360px;
  white-space: nowrap;
}

.form-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.compangLogo {
  width: 62px;
  height: 62px;
  user-select: none; /* Prevents text selection */
  margin-bottom: 24px;
}

.form-header h2 {
  margin-top: 0;
  text-align: center;
  font-size: var(--font-size-display-sm);
  color: var(--color-gray-900);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-display-sm);
  margin-bottom: var(--font-size-xs);
}

.form-header p {
  margin-top: 0;
  font-size: 13px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--color-gray-700);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-md);
  margin-bottom: var(--font-size-lg);
}

.login-container .formArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 360px;
}

.addtionArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  font-size: 13px;
  width: 100%;
}

/* the jump source text used for "Forgot password" */
.jumpSource{
  color: var(--blue-600);
  font-weight: 600;
  cursor: pointer;
}

.jumpSource:active{
  color: var(--blue-700);
}

.jumpSource:hover{
  color: var(--blue-700);
}

.image-container {
  display: flex;
  width: calc(50vw - 24px);
  height: calc(100% - 24px);
  justify-content: center;
  align-items: center;
  border-radius: var(--Font-height-text-xxs, 16px);
  background-image: url('../../../image/Background.png');
  background-size: cover;
  user-select: none; /* Prevents text selection */
}

/* the position of every image */
.imageBig {
  width: 100%;
  height: 90vh;
  object-fit: contain;
  pointer-events: none;
}

/* if the width less than 1024px */
@media (max-width: 1024px) {
  .container {
    padding: 0; /* remove the padding */
    justify-content: center; /* center in x-asix */
    align-items: center; /* center in y-asix */
  }

  .loginComp {
    width: 100%; 
    height: 100%; 
    display: flex;
    justify-content: center; /* center in x-asix */
    align-items: center; /* center in y-asix */
    opacity: 1;
  }

  .image-container {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
  }
}
