/* Achieve moduel: Home Page Part */
.achieveHome{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100vw - 52px);
    margin-top: 1px;
    margin-right: 3px;
    min-height: calc(100vh - 42px);
    max-height: calc(100vh - 42px);
    padding: 24px;
    gap: 12px;
    flex: 1 0 0;
    box-sizing: border-box;
    overflow: hidden;
    font-family: var(--font-family-display);
    overflow-y: auto;
}

.achieveHome::-webkit-scrollbar {
    width: 7px;
}

.achieveHome::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-300);
    cursor: pointer;
    border-radius: 3px;
}

.achieveHome::-webkit-scrollbar-track {
    background-color: var(--color-gray-50);
}

.achieveHome::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-400);
}


.addChartButtonArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-self: stretch;
    margin-bottom: 12px;
}

.addChartButtonAreaLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-md);
    color: var(--color-gray-950);
}
