/* table the all area */
.table-container {
  height: calc(100vh - 205px);
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: var(--font-family-body);
  overflow: hidden;
}

/* the area could scroll able */
.table-scrollable-area {
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column; 
  width: 100%;
  height: 100%;
}

.table-scrollable-area::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.table-scrollable-area::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-500);
  cursor: pointer;
  border-radius: 3px;
}

.table-scrollable-area::-webkit-scrollbar-track {
  background-color: var(--color-gray-50);
}

.table-scrollable-area::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-600);
}


/* the header area of issue table */
.table-header {
  position: sticky; /* make sure the header area  will always display and not hidden it*/
  top: 0px; /*the headder area will always display in the top*/
  display: flex;
  background-color: var(--color-dark-gray-50);
  border-bottom: 1px solid var(--color-gray-300);
  width: fit-content;
  min-width: 100%;
  z-index: 50; /* make sure the header area row will always display and not hidden it*/
}

/* the single cell of issue table header area */
.header-cell {
  padding: 0px 12px;
  box-sizing: border-box;
  font-size: var(--font-size-xs);
  font-weight: bold;
  color: var(--color-gray-800);
  text-align: left;
  pointer-events: all;  /* ==> It allowed user could drop the column and drop event could be cateched */
  flex-shrink: 0; /* Prevent cells from shrinking */
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative; /* For resize handle positioning */
}

/* the header content */
.header-content {
  flex: 1;
  display: flex;
  align-items: center;
}

.header-content::first-letter {
  text-transform: uppercase;
}

/* Resize handle ==> change the column width */
.resize-handle {
  position: absolute;
  right: 0;
  top: 30%;
  bottom: 0;
  width: 2px;
  border-radius: 4px;
  height: 40%;
  background-color: var(--color-gray-300);
  cursor: col-resize;
  transition: background-color 0.2s;
}

/* the line of column width resing (google sheet feature)*/
.resize-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 4px;
  background-color: var(--color-gray-300);
  pointer-events: none; /* not event for mouse */
  z-index: 150;
}

/* the line of row height resing (google sheet feature)*/
.row-resize-line {
  position: fixed;
  left: 14px;
 
  height: 4px;
  background-color: var(--color-gray-300);
  pointer-events: none;
  z-index: 90;
}

.selected-row {
  background-color: var(--blue-500);
}

/* the two lines of checkbox column botton used to start resizing row height*/
.row-resize-handle {
  opacity: 1;
  transition: opacity 0.2s;
  width: 100%;
  height: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .row-resize-handle:hover {
  opacity: 1;
} */

.row-resize-handle div {
  width: 100%;
  height: 1px;
  background-color: var(--color-gray-400);
}


/* Content area */
.table-content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 100%;
  position: absolute;
  top: 162px;
  z-index: 30; 
}

/* Clicked issue row */
.clicked-issueRow {
  position: sticky;
  top: 41px;
  background-color: var(--brand-secondary-50) !important;
  border-bottom: 1px solid var(--color-gray-200);
  z-index: 50; /* make sure the clicked issue row will always display and not hidden it*/
}

/* Scrollable rows area */
.table-rows {
  height: auto;
  max-height: 100%;
}

/* single Row styles */
.table-row {
  display: flex;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid var(--color-gray-300);
  width: fit-content;
  min-width: 100%;
}

/* Row styles (odd) index */
.table-row:nth-child(odd) {
  background-color: var(--color-dark-gray-50);
}

/* Row styles (even) index */
.table-row:nth-child(even) {
  background-color: var(--color-white);
}
/* Row effect style */
.table-row:hover {
  background-color: #F0F0F0;
}

/* Row styles (current row) */
.table-row.current-row {
  background-color: var(--blue-25) !important;
}

/*it will not allowed any click event */
.stickyColumn-cell{
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: var(--font-size-xs);
  color: var(--color-gray-900);
  line-height: 18px;
  flex-shrink: 0;  /* Prevent cells from shrinking */
  transition: width 0.3s ease;
  display: inline-block; /* WebKit-based browsers */
  -webkit-box-orient: vertical; /* WebKit-specific */
  box-orient: vertical; /* Older spec fallback */
  overflow: hidden; /* Required for line-clamp to work */
  text-overflow: ellipsis;
  line-clamp: 4;
  -webkit-line-clamp: 4; /* WebKit-specific */ 
}

/* Cell styles */
.table-cell {
  padding: 8px 12px;
  box-sizing: border-box;
  font-size: var(--font-size-xs);
  color: var(--color-gray-900);
  line-height: 18px;
  flex-shrink: 0;  /* Prevent cells from shrinking */
  transition: width 0.3s ease;
  pointer-events: all;  /* ==> It allowed user could drop the column and drop event could be cateched */
  display: inline-block; /* WebKit-based browsers */
  -webkit-box-orient: vertical; /* WebKit-specific */
  box-orient: vertical; /* Older spec fallback */
  overflow: hidden; /* Required for line-clamp to work */
  text-overflow: ellipsis;
  line-clamp: 4;
  -webkit-line-clamp: 4; /* WebKit-specific */ 
}

/* the columns area (checkbox and star column) will not follow x-axis scroll bar move */
/* the sticky columns area: checkbox and star column*/
.sticky-columns {
  position: sticky;
  display: flex;
  justify-content: center;
  left: 0;
  z-index: 90; /* z-index should large than issue table rows ==> sticky columns should always visable */
  background-color: var(--color-dark-gray-50); /* need follow the current row, click issue row color */
}

/* add the line to split the general area with left sticky columns area */
.sticky-columns::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1.5px;
  background-color: var(--color-gray-300);
}

/* Checkbox styles */
.checkbox-header,
.checkbox-cell {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 2px 0px;
  flex-shrink: 0; /* Prevent cells from shrinking */
}

/* Footer styles */
.table-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 40px;
  border-top: 1px solid var(--color-gray-300);
  font-family: var(--font-family-display);
  font-size: 12px;
}

/* Issue Table footer area */
.table-footer select {
  padding: 4px;
  border-radius: 4px;
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-display);
  font-size: 14px;
}

.pagination-info{
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Footer area: page selection area UI */
.rowsSelection{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

/* Footer area: The page navigation button */
.pagination-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

/* Selection disabled state */
.table-container * {
  user-select: none;
}

/* system edit button  */
.system-edit-button {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 4px;
    padding: 8x 12px;
    cursor: pointer;
    border: 1px solid var(--color-gray-300);
    font-family: var(--font-family-display);
    color: var(--color-gray-700);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-sm);
}

.system-edit-button:hover {
    background-color: var(--color-gray-100);
    color: black;
}

.system-edit-button:active {
    background-color: var(--color-gray-200);
    color: black;
}

/* The edit system cells */
.system-cell{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 4px;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.system-cell:hover .system-edit-button {
  opacity: 1;
}


